import { RefObject, useMemo } from "react";

import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import { InputSearchWithOptionsHandlerList } from "@sellernote/_sds-v2/src/components/form/InputSearchWithOptionsV2";

import { getOptionList, getPortType, getRecommendationList } from "../utils";

import { useSearch } from "../SearchContext";
import InputSearchForSchedule from "./InputSearchForSchedule";

export default function Pol({
  polOptionHandlerListRef,
  isMobile = false,
}: {
  polOptionHandlerListRef: RefObject<InputSearchWithOptionsHandlerList>;
  isMobile?: boolean;
}) {
  const [{ freightType, polId, podId }, setSearch] = useSearch();

  const { data: portList } = COMMON_QUERY.useGetUserPortList({
    portType: getPortType(freightType),
  });

  const polOptionList = useMemo(() => getOptionList(portList), [portList]);

  const polRecommendationList = useMemo(
    () =>
      getRecommendationList({
        portList,
        freightType,
        locationType: "pol",
        portCountryOfAnotherLocationType: portList?.find(
          (port) => port.id === podId
        )?.country,
      }),
    [freightType, podId, portList]
  );

  const handlePolSelect = (selectedPolId: number | null) => {
    setSearch({ polId: selectedPolId });
  };

  const handlePolReset = () => {
    setSearch({ polId: null });
  };

  return (
    <InputSearchForSchedule<number | null>
      className="custom-label-color"
      {...(!isMobile && { width: 296 })}
      labelInfo={{
        label: (
          <>
            출발지<span className="required">*</span>
          </>
        ),
        position: "top",
      }}
      placeholder="입력해주세요."
      searchSourceList={polOptionList}
      selectedOptionValue={polId}
      onSelect={handlePolSelect}
      onReset={handlePolReset}
      recommendationList={polRecommendationList}
      inputSearchForScheduleHandlerList={polOptionHandlerListRef}
    />
  );
}

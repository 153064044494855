import { useCallback, useMemo } from "react";
import { Trans } from "react-i18next";
import purify from "dompurify";

import { noop } from "@sellernote/_shared/src/utils/common/etc";
import FormStyled from "@sellernote/_sds-v2/src/components/form/index.styles";
import { InputSearchLabelInfo } from "@sellernote/_sds-v2/src/components/form/InputSearch";
import { InputSearchOption } from "@sellernote/_sds-v2/src/components/form/InputSearchWithOptionsV2";

interface SearchResultOptionsProps<T> {
  width: string | number;
  labelInfo?: InputSearchLabelInfo;
  searchTerm: string;
  searchSourceList: InputSearchOption<T>[];
  onSelect?: (value: T) => void;
}

export default function SearchResultOptions<T>({
  width,
  labelInfo,
  searchTerm,
  searchSourceList,
  onSelect,
}: SearchResultOptionsProps<T>) {
  const getSearchResult = () => {
    if (!searchTerm) {
      return [];
    }

    return searchSourceList.filter((searchOptionItem) =>
      searchOptionItem.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const searchResult = useMemo(getSearchResult, [searchSourceList, searchTerm]);

  const createHighlightingStyle = useCallback(
    (searchTerm: string, searchOptionList: InputSearchOption<T>[]) => {
      return searchOptionList.map((option) => {
        const searchTermRegex = new RegExp(searchTerm, "gi");

        return {
          ...option,
          label: option.label.replace(
            searchTermRegex,
            (match) => `<strong>${match}</strong>`
          ),
        };
      });
    },
    []
  );

  const highlightedSearchResults = useMemo(() => {
    return createHighlightingStyle(searchTerm, searchResult);
  }, [createHighlightingStyle, searchTerm, searchResult]);

  const handleSearchResultSelect = ({ value }: InputSearchOption<T>) => {
    onSelect?.(value);
  };

  return (
    <>
      {highlightedSearchResults.length ? (
        <FormStyled.optionList
          className="custom-option-list"
          labelPosition={labelInfo?.position || "top"}
          optionListPosition="bottom"
          width={width}
        >
          {highlightedSearchResults.map(({ label, value, disabled }, i) => (
            <li
              key={`search-option-item-${i}`}
              onClick={
                disabled
                  ? noop
                  : () => handleSearchResultSelect({ label, value })
              }
            >
              <span
                className="search-result-label"
                dangerouslySetInnerHTML={{
                  __html: purify.sanitize(label),
                }}
              />
            </li>
          ))}
        </FormStyled.optionList>
      ) : (
        <FormStyled.optionList
          labelPosition={labelInfo?.position || "top"}
          optionListPosition="bottom"
          width={width}
        >
          <li>
            <span className="no-search-results">
              <Trans i18nKey="sds-v2:InputSearchWithOptions_검색결과없음" />
            </span>
          </li>
        </FormStyled.optionList>
      )}
    </>
  );
}
